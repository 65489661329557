<template>
    <!-- 订单取消申请 -->
    <div class="order_cancel_apply_box">
        <div class="order_cancel_apply_form_box">
            <el-form label-width="100px">
                <!-- 订单编号 -->
                <el-form-item label="订单编号：">
                    <span>8012105640</span>
                </el-form-item>
                <!-- 付款金额 -->
                <el-form-item label="付款金额：">
                    <span>¥689.00</span>
                </el-form-item>
                <!-- 退还金额 -->
                <el-form-item label="退还金额：">
                    <span>¥689.00</span>
                </el-form-item>
                <!-- 取消原因 -->
                <el-form-item label="取消原因：">
                    <el-select></el-select>
                </el-form-item>
                <!-- 取消说明 -->
                <el-form-item label="取消说明：">
                    <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容">
                    </el-input>
                </el-form-item>
                <!-- 提交按钮 -->
                <el-form-item>
                    <el-button
                        type="warning"
                        class="cancel_apply_btn">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
/* 订单取消申请盒子 */
.order_cancel_apply_box{
    width: 800px;
    margin: 0 auto;
    border: 1px solid #DCDFE6;
}
/* 订单取消申请表单盒子 */
.order_cancel_apply_form_box{
    width: 600px;
    margin: 20px auto;
}
/* 取消申请提交按钮 */
.cancel_apply_btn{
    width: 150px;
    height: 50px;
}
</style>
